import { Link } from "@StarberryUtils";
import React,{useState, useEffect} from "react";
import {Row, Col, Container,Navbar,Nav,NavDropdown,Form, Button } from 'react-bootstrap';
import BgShadow from "../../images/bg-shadow.svg";
import  "../News/NewsHead/NewsHead.scss";
//import ScrollAnimation from 'react-animate-on-scroll';
import { useStaticQuery, graphql } from "gatsby";

import Search from "../News/NewsHead/search-form"

import { VIDEOS_PAGE_URL } from "../common/site/constants";



const VideosHead = (props) =>{


    const data = useStaticQuery(graphql`
    query{
      allStrapiVideosParentCategories(filter: {Publish: {eq: true}}, sort: {order: ASC, fields: Sort}) {
      edges {
        node {
          Name
          URL
          Sort
        }
      }
    }
    }
  `);


  const[addSearch,setAddSearch] = useState(false);

    const addingSearch=()=>{
      setAddSearch(!addSearch)
  }


    const  outSideLink= (e) => {
    if(!e.target.className.includes("icon-search-icon") && !e.target.className.includes('form-control')
     && !e.target.className.includes('search-field d-flex align-items-center') ) {
      setAddSearch(addSearch)
    }
      }


    useEffect(()=>{
      document.addEventListener('click',outSideLink)
    },[])
   
    return(
  <div className="news-head text-center news-head-filter" >
    <Container>
        <Row className="align-items-center"> 
        <Col lg={12}>
          {/*<ScrollAnimation animateIn='fadeInUp' animateOnce>*/}
            <Navbar className="justify-content-center">
              <Nav className="text-center">
                <Link to={`${VIDEOS_PAGE_URL.alias}`} className={`nav-link people-filter-desktop ${props.tag == 'All Videos' ? 'active' : ''}`}>All Videos</Link>

                {data.allStrapiVideosParentCategories.edges.map(({node}, index, active) => {
                return (
                  <Link to={`${VIDEOS_PAGE_URL.alias}/${node.URL}`} className={`nav-link people-filter-desktop ${props.tag == node.Name ? 'active' : ''}`} key={index}>{node.Name}</Link>
                )
                })}

                

                <NavDropdown title={props.tag} id="nav-dropdown office-name-dropdown-title" className="people-office-menu-head people-listing-office-list people-filter-mobile"> 

                <NavDropdown.Item href={`${VIDEOS_PAGE_URL.alias}`} className={`nav-link people-filter-mobile ${props.tag === 'All Videos' ? 'active' : ''}`}>All Videos</NavDropdown.Item>

                {data.allStrapiVideosParentCategories.edges.map(({node}, index, active) => {
                return (
                <NavDropdown.Item href={`${VIDEOS_PAGE_URL.alias}/${node.URL}`} eventKey={index+1} className={`nav-link people-filter-mobile ${props.tag === node.Name ? 'active' : ''}`} key={index} data-filter={node.URL}>{node.Name}
                </NavDropdown.Item>

                )
                })}
                </NavDropdown>



                <Nav.Link className="search-control" href="#"><i className="icon-search-icon" onClick={e=>addingSearch()}></i></Nav.Link>
                {
                  addSearch ?
                <Search text={props.text} handlechange={props.handlechange}/>
                :
                !addSearch
                }
              </Nav> 
            </Navbar>
          {/*</ScrollAnimation>*/}
        </Col>
      </Row>
    </Container>
  </div>
)

}

export default VideosHead;